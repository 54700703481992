/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

/* Layout */
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Quill Text Editor for p-editor */
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";

/* Demos */
@import "assets/demo/styles/flags/flags.css";
@import "assets/demo/styles/badges.scss";
@import "assets/demo/styles/code.scss";

:root {
    --main-blue: #265f2e;
    --main-red: #E84743;
}

.text-danger {
    color: red;
}


.section-authentication-signin {
    height: 100vh;
    background: linear-gradient( 0deg, rgb(0 105 57 / 25%), rgb(248 89 33 / 30%)),
    url(assets/layout/images/bg.avif);
    background-size: cover;
    background-position: center center;
}

.bg-blue {
    background-color: var(--main-blue);
}
