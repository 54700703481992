$primary: #6a717b;
$secondary: #439259;
$white: #FFFFFF;

.layout-sidebar {
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    width: 16rem;
    background: var(--menu-bg);
    border-right: var(--sidebar-border);
    box-shadow: var(--sidebar-shadow);
    display: flex;
    flex-direction: column;

    .app-logo {
        padding: 2.5rem 0 2rem 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .app-logo-normal {
            display: inline;
        }

        .app-logo-small {
            display: none;
        }
    }

    .layout-menu-container {
        overflow: auto;
        flex: 1;
        padding-bottom: 2rem;
        background-color: $primary;
    }

    .layout-menu {
        margin: 0;
        padding: 0;
        list-style-type: none;
        background-color: $primary;

        .layout-root-menuitem {
            > .layout-menuitem-root-text {
                font-size: .857rem;
                text-transform: uppercase;
                font-weight: 700;
                // color: var(--root-menuitem-text-color);
                color: $white;
                padding: 1rem 2rem;
            }

            > a {
                display: none;
            }
        }

        a {
            user-select: none;

            &.active-menuitem {
                > .layout-submenu-toggler {
                    transform: rotate(-180deg);
                }
            }
        }

        li.active-menuitem {
            > a {
                .layout-submenu-toggler {
                    transform: rotate(-180deg);
                }
            }
        }

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            a {
                display: flex;
                align-items: center;
                position: relative;
                outline: 0 none;
                color: $white;
                //color: var(--menuitem-text-color);
                cursor: pointer;
                padding: .75rem 2rem;
                transition: background-color var(--transition-duration), box-shadow var(--transition-duration);

                .layout-menuitem-icon {
                    margin-right: .5rem;
                    color: $white;
                    //color: var(--menuitem-icon-color);
                }

                .layout-submenu-toggler {
                    font-size: 75%;
                    margin-left: auto;
                    transition: transform var(--transition-duration);
                }

                &.active-route {
                    font-weight: 700;
                    background-color: $secondary;
                    color: $white;
                }

                &:hover {
                    background-color: $secondary;
                    color: $white;
                    font-weight: 700;
                    //background-color: var(--menuitem-hover-bg-color);
                }
            }

            ul {
                overflow: hidden;
                border-radius: var(--border-radius);

                li {
                    a {
                        padding-left: 2.5rem;
                    }

                    li {
                        a {
                            padding-left: 3rem;
                        }

                        li {
                            a {
                                padding-left: 3.5rem;
                            }

                            li {
                                a {
                                    padding-left: 4rem;
                                }

                                li {
                                    a {
                                        padding-left: 5.5rem;
                                    }

                                    li {
                                        a {
                                            padding-left: 5rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
